import Headroom from 'headroom.js';
import Aos from 'aos';

(function () {
  'use strict';

  document.addEventListener('DOMContentLoaded', handleDOMContentLoaded);

  const creditsTrigger = document.querySelector('#open-credits');
  const creditsLayer = document.querySelector('#credits-layer');
  const creditsClose = document.querySelector('#credits-close');
  const header = document.querySelector('.main-header');
  const scrollMe = document.querySelector('.please-scroll-me');
  const headerContent = document.querySelector('.main-header__content');
  const menu = document.querySelector('.main-navigation');

  function handleDOMContentLoaded() {
    new Headroom(header, {
      offset: {
        down: 50,
        up: 100
      },
      tollerance: {
        up: 30,
        down: 0
      },
      classes: {
        notTop: 'main-header--short'
      }
    }).init();

    new Headroom(scrollMe, {
      classes: {
        notTop: 'please-scroll-me--thanks'
      }
    }).init();

    const menuTrigger = document.querySelector('.main-burger');
    menuTrigger.addEventListener('click', handleMobileMenu);

    const mobile = window.matchMedia("(max-width: 1199px)");
    handleMobileChange(mobile);
    mobile.addListener(handleMobileChange);

    if (creditsTrigger) {
      creditsTrigger.addEventListener('click', handleOpenCredits);
      creditsClose.addEventListener('click', handleCloseCredits);
    }

    const shareButton = document.querySelector('.share-button');
    if (shareButton) {
      if (navigator.share) {
        shareButton.addEventListener('click', openShare);
      } else {
        shareButton.classList.add('is-hidden');
      }
    }

    Aos.init({
      once: true
    });
  }

  function handleMobileChange(e) {
    if (e.matches) {
      header.insertAdjacentElement('afterend', menu);
    } else {
      headerContent.appendChild(menu);
    }
  }

  function handleMobileMenu(e) {
    e.preventDefault();

    if (menu.classList.contains('main-navigation--show')) {
      menu.classList.remove('main-navigation--show');
      e.currentTarget.classList.remove('is-active');
    } else {
      menu.classList.add('main-navigation--show');
      e.currentTarget.classList.add('is-active');
    }
  }

  function handleOpenCredits(e) {
    e.preventDefault();

    creditsLayer.classList.add('credits-layer--show');
  }

  function handleCloseCredits(e) {
    e.preventDefault();

    creditsLayer.classList.remove('credits-layer--show');
  }

  function openShare(e) {
    e.preventDefault();

    const title = e.target.getAttribute('data-title');
    const url = e.target.getAttribute('data-url');
    navigator.share({ title, url });
  }
})();
